/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "La semana pasada estuvimos probando el control P, vimos que al tener solo un grado de libertad no obteníamos unas respuestas totalmente a nuestro gusto. Hoy vamos a mejorar esto añadiéndole al control una componente derivativa, lo que se conoce también como acción derivada."), "\n", React.createElement(_components.p, null, "El mecanismo de la acción derivada es sencillo, intenta corregir la pendiente del error, esto provoca que la salida se suavice, es decir, que no tenga grandes pendientes y con esto conseguimos reducir e incluso eliminar la sobreoscilación."), "\n", React.createElement(_components.p, null, "Igual que el último día podéis descargar la función controlPD, de ", React.createElement(_components.a, {
    href: "http://entramado.net/cursocontrol/ControlLearningToolbox/contro.m"
  }, "aquí"), ", el funcionamiento es muy parecido:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"matlab\"><pre class=\"language-matlab\"><code class=\"language-matlab\"><span class=\"token function\">controlPD</span><span class=\"token punctuation\">(</span>kp<span class=\"token punctuation\">,</span>Td<span class=\"token punctuation\">)</span></code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "El sistema a controlar no ha cambiado, pero ahora podemos modificar dos parámetros para mejorar el control: kp, que afecta a la parte proporcional y Td que afecta a la parte derivativa."), "\n", React.createElement(_components.p, null, "Vamos a probar algo sencillo:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"matlab\"><pre class=\"language-matlab\"><code class=\"language-matlab\"><span class=\"token function\">controlPD</span><span class=\"token punctuation\">(</span><span class=\"token number\">1</span><span class=\"token punctuation\">,</span><span class=\"token number\">1</span><span class=\"token punctuation\">)</span></code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Y obtenemos la siguiente respuesta:"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 560px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/e14be33e6f46fa38e80bd389109a83d4/b06ae/controlPD11.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 77.21518987341771%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABS0lEQVQoz5WS626DMAyF+/4vWQmpIyEhFyiJc7MnMIKul03zr6NYx5/t+EJbDMMgpTTGDMOgtTbGCCGUkuOoN6EeU0qpeZ6J6IKIRKRG5YKDBktaQg6xhnta/BLsPSob7D0sOWi3aB/MHAc7GWNWMxG11qSUKSXWRJgSTlOLEUvBtgYismhEBADW2t28kpViM+LqAUCuQoS79af5JBORMSbnvCUwRtqqIKceBeuc80kupfR9nxK0htNUmVFrZdSTQMQY406utQKAc66UDLCSX4F/k2OEELC198BDENFJ5qrO2RDyvjL8D5mItFbep9bo8WOOJf+2bcQmhPR+/+dXz0fzfmHKTlP+1O2TONvmp64TX1/CWntcct/3Wmvn3PFyCCml9/6ceTsmOtpmDgDM88yX95RicbndbtfrdRzHruuOxjhqrSklnvNtfAMxImwwF2LX1AAAAABJRU5ErkJggg=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Control PD con kp 1 y Td 1.\"\n        title=\"Control PD con kp 1 y Td 1.\"\n        src=\"/static/e14be33e6f46fa38e80bd389109a83d4/b06ae/controlPD11.png\"\n        srcset=\"/static/e14be33e6f46fa38e80bd389109a83d4/c26ae/controlPD11.png 158w,\n/static/e14be33e6f46fa38e80bd389109a83d4/6bdcf/controlPD11.png 315w,\n/static/e14be33e6f46fa38e80bd389109a83d4/b06ae/controlPD11.png 560w\"\n        sizes=\"(max-width: 560px) 100vw, 560px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Vemos que el control es algo más rápido y se ha eliminado la sobreoscilación que teníamos con el control P."), "\n", React.createElement(_components.p, null, "Ahora toca jugar un poco. ¿Hasta donde podéis llegar con estos dos parámetros?"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
